import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";

@Component({
  selector: 'app-rapport-mensuel',
  templateUrl: './rapport-mensuel.component.html',
  styleUrls: ['./rapport-mensuel.component.scss']
})
export class RapportMensuelComponent implements OnInit {
    itemToSave: any = {};
    busyGet: Subscription;
    busySave: Subscription;
    listTypeAntecedent: Array<any> = [];
    listAntecedentToSave: any = [];
    user: any = {};
    loading: boolean;
    currentData: any;
    currentDossier: any;
    dossierId: any;
    modalRef?: BsModalRef;
    title = ''
    listAssurances: any;
    confirmnewPassword: any;
    currentLink: any
    fileUrl: any;
    constructor(public bsModalRef: BsModalRef, private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
      this.user = this.authService.currentUserValue;
      setTimeout(() => {
        this.fileUrl = this.currentLink
        console.log('this.fileUrl: ',this.fileUrl);
        
      }, 1000);
  
    }
  
    
  
    ngOnInit(): void {
  
    }
  

}
