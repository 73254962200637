import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
    {
        path: "/tableau-de-bord",
        title: "Tableau de bord",
        moduleName: "admin",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "badge bg-blue sidebar-badge float-end",
        role: ["Admin", "Doctor"],
        code: "DASHBOARD",
        submenu: [],
    },
    {
        path: "/localisation",
        title: "Localisation",
        moduleName: "admin",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "badge bg-blue sidebar-badge float-end",
        role: ["Admin", "Doctor"],
        code: "ADMIN",
        submenu: [],
    },
    // {
    //   path: "/controle-hospitalisation",
    //   title: "Controle hospitalisation",
    //   moduleName: "admin",
    //   iconType: "material-icons-two-tone",
    //   icon: "event_note",
    //   class: "",
    //   groupTitle: false,
    //   badge: "",
    //   badgeClass: "badge bg-blue sidebar-badge float-end",
    //   role: ["Admin", "Doctor"],
    //   submenu: [],
    // },
    {
        path: "controle-hospitalisation",
        title: "Controle hospitalisation",
        moduleName: "controle-hospitalisation",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "badge bg-blue sidebar-badge float-end",
        role: ["Admin", "Doctor"],
        code: "CONTROL_HOSPIT",
        submenu: [
            {
                path: "/controle-hospitalisation/visite-patients",
                title: "Visite",
                moduleName: "controle-hospitalisation",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "VISITE",
            },
            {
                path: "/controle-hospitalisation/rapport-visite",
                title: "Rapport visite",
                moduleName: "controle-hospitalisation",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "RAPPORT_VISITE",
            },
        ],
    },
    {
        path: "administration",
        title: "Administration",
        moduleName: "administration",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "badge bg-blue sidebar-badge float-end",
        role: ["Admin", "Doctor"],
        code: "ADMIN",

        submenu: [
            {
                path: "/administration/utilisateurs",
                title: "Utilisateurs",
                moduleName: "administration",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "UTILISATEURS",
            },
            {
                path: "/administration/roles",
                title: "Roles",
                moduleName: "administration",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "ROLES"
            },
            {
                path: "/administration/fonctionnalites",
                title: "Fonctionnalités",
                moduleName: "administration",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "FONCTIONNALITES"
            },
        ],
    },
    {
        path: "parametres",
        title: "Paramètres",
        moduleName: "parametres",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "badge bg-blue sidebar-badge float-end",
        role: ["Admin", "Doctor"],
        code: "PARAMETRES",
        submenu: [
            {
                path: "/parametres/secteurs",
                title: "Secteurs",
                moduleName: "parametres",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "SECTEUR",
            },
            {
                path: "/parametres/configuration",
                title: "Configuration",
                moduleName: "parametres",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
                code: "CONFIGURATIONS",
            },
        ],
    },
];
