<ng-container>
    <div class="row search-part">
        <div class="col-md-12 col-sm-12 mb-0">

            <div class="row">
                <div class="col-md-12 col-sm-12 mb-0">
                    <input class="form-control custom-input" placeholder="Rechercher centre" autocomplete="off"
                        [(ngModel)]="itemToSearch.searchText" (keyup)="getItems()" name="searchText" type="text">
                </div>
            </div>
            <div class="row search-adherent-part" *ngIf="canDisplay">
                <table class="table table-condensed table-bordered table-striped table-hover">
                    <thead>
                        <tr class="custom-table-head">
                            <th>#</th>
                            <th>Libellé</th>
                            <th>Adresse</th>
                            <th>Contact</th>

                            <th>

                                <a class="pull-right" title="Fermer" (click)="canDisplay = false">
                                    <i class="fa fa-times"></i>
                                </a>
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let item of listItem; let indice = index" (click)="selectedItem(item)"
                            style="cursor: pointer;">
                            <td>
                                {{indice + 1}}
                            </td>
                            <td>
                                {{item?.lb_centre}}
                            </td>
                            <td>{{item?.adr_centre}}</td>
                            <td>{{item?.tel_centre}}</td>
                        </tr>
                        <tr *ngIf="!listItem || listItem.length == 0">
                            <td colspan="5" style="text-align: center;">
                                <span> Aucune donnée disponible</span>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>

    </div>
</ng-container>