import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse
} from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
// import { AuthService } from '../service/auth.service';
// import { User } from '../models/user';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

//   user: User;
constructor(private authService: AuthService){
//   this.user = this.authService.currentUserValue
}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.connectedUser().token
    
    console.log("token: ",token);
    if (token) {
      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      
    }

    // httpRequest.body.checkToken=1

    // return next.handle(httpRequest);
    return next.handle(httpRequest).pipe(
      map((res:any) => {
         let body = {...res.body}
         let status = {...body.status}
         if(status.message == 'Utilisateur non connecté'){
          if(confirm('Session expirée')){
            this.authService.logout()
          }
          // this.authService.logout()
         }
         else{
         return res
         }
      }
      ),
);

  }
}