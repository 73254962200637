import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';

@Component({
    selector: 'app-search-centre',
    templateUrl: './search-centre.component.html',
    styleUrls: ['./search-centre.component.scss']
})
export class SearchCentreComponent implements OnInit {

    @Output() itemCentreSelected = new EventEmitter<any>();
    @Input() cancelItemSelected: any;
    @Input() isClearUp: any;

    modalRef: BsModalRef;
    itemToSearch: any = {};
    itemToSave: any = {};
    listItem: Array<any> = [];
    user: any = {};
    busyGet: Subscription;
    itemSelected: any = {};
    canDisplay: boolean = false;
    isForCompta: boolean = true

    constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
        this.user = this.authService.connectedUser();
    }

    getItems() {

        // On affiche le tableau des patients
        this.canDisplay = true;

        let request = {
            user: this.user.id,
            data: {
                lb_centre: this.itemToSearch.searchText ? this.itemToSearch.searchText : null
            },
            index: 1,
            size: 10
        }
        console.log('data sent: ', JSON.stringify(request));

        this.busyGet = this.restClient.post('centre/centreCanHospit', request)
        // this.busyGet = this.restClient.post('centre/getByCriteria', request)
            .subscribe(
                res => {
                    if (res && res['items']) {
                        this.listItem = res['items'];
                    }
                    else {
                        this.listItem = [];
                    }
                },
                err => {
                }
            );
    }


    openModal(data: any, template: TemplateRef<any>) {

        let config = { backdrop: true, ignoreBackdropClick: true };

        this.itemToSave = {};
        if (data) {
            // Lorsque nous sommes en modification
            this.itemToSave = Object.assign({}, data);
        }

        this.modalRef = this.modalService.show(template, Object.assign({}, config, { class: 'modal-lg modal-width-75' }));
    }

    selectedItem(item: any) {
        console.log('item', item);

        if (item) {
            this.itemSelected = { ...item };
            this.itemToSearch.searchText = this.itemSelected.lb_assur;

            // Brodcast de la valeur
            this.itemCentreSelected.emit(this.itemSelected);

            // On ferme le tableau de selection des patients
            this.canDisplay = false;
            this.itemToSearch.searchText = item.lb_centre
            if (this.isClearUp == 'true') {
                this.itemToSearch.searchText = null
            }


        }
    }

    patientSaved($event) {
        if ($event) {
            this.selectedItem($event);

            // Après ça on ferme le modal
            this.modalRef.hide();
        }
    }

    ngOnInit(): void {

    }


    ngOnChanges(changes: SimpleChanges) {

        if (changes['cancelItemSelected'] && changes['cancelItemSelected'].currentValue) {
            this.itemSelected = {};
            this.itemToSearch.searchText = null;
        }
    }


}
