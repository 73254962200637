import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-resizable-pagination',
  templateUrl: './resizable-pagination.component.html',
  styleUrls: ['./resizable-pagination.component.scss']
})
export class ResizablePaginationComponent implements OnInit {


    @Output() paginationChange  = new EventEmitter<any>();
    @Input() defaultItemPerPage : any;
  
    itemPagination : any;
    constructor() { }
  
    changePagination() {
  
      if(this.itemPagination) {
        this.paginationChange.emit(this.itemPagination);
      }else {
        this.paginationChange.emit(10000);
      }
  
    }
  
    ngOnInit(): void {
      this.itemPagination = this.defaultItemPerPage
    }

}
