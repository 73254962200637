<div class="modal-header">
    <h4 class="modal-title pull-left">
        {{ title | titlecase }}
    </h4>

    <button mat-icon-button class="pull-right" (click)="bsModalRef.hide()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="modal-body" [ngBusy]="{
      busy: busySave,
      message: 'Veuillez patienter ...',
      backdrop: false
    }">
    <ng-container>
        <div class="row">
            <form class="m-1" style="min-height: 400px;">

                <div class="row">

                    <div class="col col-md-12" *ngIf="fileUrl">

                        <object class="isPdf" *ngIf="currentLink.type == 'isPdf'" [data]="fileUrl | safe"
                            type="application/pdf">
                            <!-- <embed [src]="fileUrl | safe" type="application/pdf" style="height:388px;width:100%"
                                id="embedPdf" /> -->
                            <iframe [src]="fileUrl | safe" frameborder="0" height="100%" width="100%"
                                style="width: 100%; height: 100%;" frameborder="0" scrolling="no"></iframe>

                        </object>
                        <img *ngIf="currentLink.type != 'isPdf'" [src]="fileUrl | safe" alt="Carte" class="responsive isImage">
                        <!-- <iframe class="img-responsive" [src]="fileUrl | safe"
                            [ngClass]="{'isPdf':currentLink.type == 'isPdf','isImage':currentLink.type != 'isPdf'}"></iframe> -->
                        <!-- <object data="pic_trulli.jpg" width="300" height="200"></object> -->

                    </div>

                </div>
            </form>
        </div>
    </ng-container>
</div>

<div class="modal-footer modal-footer-custom">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <!-- <button type="button" (click)="confirmSaveItem(itemToSave)" class="btn btn-md custom_btn_primary pull-right"
            [class.auth-spinner]="loading" [disabled]="loading">
            {{ itemToSave?.id ? "Enregistrer" : "Enregistrer" }}
        </button> -->

        <button type="button" class="btn btn-md btn-cancel pull-right" (click)="bsModalRef.hide()">
            Annuler
        </button>
    </div>
</div>