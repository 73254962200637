import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { NgBusyModule } from "ng-busy";
import { NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MoneyFormatPipe } from "../core/pipes/money-format.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { SafePipe } from "../core/pipes/safe.pipe";
import { SearchCentreComponent } from "./components/search-centre/search-centre.component";
import { AddDocumentComponent } from "./components/add-document/add-document.component";
import { ChartModule } from 'angular-highcharts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DateFormatPipe } from "../core/pipes/date-format.pipe";
import { RapportMensuelComponent } from "./components/rapport-mensuel/rapport-mensuel.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { ModalPreviewRapportComponent } from "./components/modal-preview-rapport/modal-preview-rapport.component";
import { ResizablePaginationComponent } from "./components/resizable-pagination/resizable-pagination.component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AgmCoreModule } from '@agm/core';
@NgModule({
    declarations: [MoneyFormatPipe, SafePipe, SearchCentreComponent, 
        AddDocumentComponent, 
        DateFormatPipe,RapportMensuelComponent,
        FileUploadComponent, 
        ModalPreviewRapportComponent,FileUploadComponent,ResizablePaginationComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        NgxMaskModule.forRoot(),
        NgBusyModule,
        PaginationModule.forRoot(),
        // PaginationModule.forRoot(),
        BsDatepickerModule.forRoot(),
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTabsModule,
        ChartModule,
        NgxCaptchaModule,
        NgMultiSelectDropDownModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBh72gI7aKIw1in5oqcIyNJChGVTY3ZjKw'
          })


    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        MaterialModule,
        FeatherIconsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        NgBusyModule,
        PaginationModule,
        MoneyFormatPipe,
        BsDatepickerModule,
        MatTabsModule,
        SafePipe,
        SearchCentreComponent,
        AddDocumentComponent,
        ChartModule,
        NgxCaptchaModule,
        DateFormatPipe,
        RapportMensuelComponent,
        FileUploadComponent, 
        ModalPreviewRapportComponent,
        ResizablePaginationComponent,
        NgMultiSelectDropDownModule,
        AgmCoreModule

    ],
})
export class SharedModule { }
