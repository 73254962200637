import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import Swal from "sweetalert2";
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import { PermissionService } from 'src/app/core/service/permission.service';

@Component({
  selector: 'app-modal-edit-profil',
  templateUrl: './modal-edit-profil.component.html',
  styleUrls: ['./modal-edit-profil.component.scss']
})
export class ModalEditProfilComponent implements OnInit {
    listItems: Array<any> = [];
    items: Array<any> = [];
    itemToSave: any = {};
    modalRef: BsModalRef;
    user: any = {};
    itemToSearch: any = {};
    currentPage: number = 1;
    itemsPerPage: number = 10;
    totalItems: number;
    busyGet: Subscription;
    busySave: Subscription;
    loading: boolean = false;
    endPoint: string = 'iwUsers';
    maxSize = 10
    title = "Controlleur"
    isEditMode: boolean = true
    maxValue: any;
    itemsRoles: any;
    itemsSecteur: any;
    selectedFile: any;
    imageDisplay ='assets/images/custom-images/anonymous-user.jpg'

    maxDate = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth() - 1,
        new Date().getDay()
      );

    constructor(public bsModalRef: BsModalRef,private authService: AuthService, private restClient: RestClientService, private permission: PermissionService, private modalService: BsModalService, private utilities: UtilitiesService) {
        this.user = this.authService.connectedUser()

    }


    pageChanged(event: any): void {
        this.currentPage = event.page;
        // this.getItems();
    }
    resetForm() {
        this.itemToSave.typeActeId = null
        this.itemToSave.libelle = ''
    }

    confirmSaveItem(item) {

        let objToSave = Object.assign({}, item);


        if (!item || !item.nom) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner nom !",
                "top",
                "right");
            return;
        }

        if (!item || !item.prenom) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner prenom !",
                "top",
                "right");
            return;
        }
        if (!item || !item.role) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner role !",
                "top",
                "right");
            return;
        }

        if (!item || !item.secteur) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner secteur !",
                "top",
                "right");
            return;
        }

        if (!item || !item.contact) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner contact !",
                "top",
                "right");
            return;
        }
        if (!item || !item.email) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner email !",
                "top",
                "right");
            return;
        }
        if (!item || !item.email) {
            this.utilities.showNotification("snackbar-danger", "Veuillez renseigner email !",
                "top",
                "right");
            return;
        }


        Swal.fire({
            title: this.isEditMode ? "Modification" : "Création",
            text: this.isEditMode ? "Vous êtes sur le point de modifier un élément. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point d'enregistrer un élément. Voulez-vous poursuivre cette action ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0665aa",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: 'Non',
        }).then((result) => {
            if (result.value) {
                // objToSave.libelle = objToSave.libelle.toUpperCase();
                this.saveItem(objToSave);
            }
        });
    }

    

    saveItem(item) {

        this.loading = true;

        let itemAEnregistrer = Object.assign({}, item);
        if (itemAEnregistrer.created_at) delete itemAEnregistrer.created_at
        if (itemAEnregistrer.updated_at) delete itemAEnregistrer.updated_at
        var request = {
            user: this.user.id,
            datas: [
                itemAEnregistrer
            ]
        }
        let fd = this.setFormDataProperties(itemAEnregistrer)
        console.log('data sent to server', JSON.stringify(request));
        // this.busySave = this.restClient.post(this.endPoint + '/' + (this.isEditMode ? 'update' : 'create'), request)
        this.busySave = this.restClient.post(this.endPoint + '/' + (this.isEditMode ? 'update' : 'create'), fd)

            .subscribe(
                (res: any) => {
                    console.log("resul", res);
                    this.loading = false;

                    if (!res['hasError']) {
                        this.bsModalRef.hide();
                        this.utilities.showNotification("snackbar-success",
                                this.utilities.formatMsgServeur(res['status']['message']),
                                "top",
                                "right");
                        this.authService.logout()
                    } else {
                        if (res['status'] && res['status']['message']) {
                            this.utilities.showNotification("snackbar-danger",
                                this.utilities.formatMsgServeur(res['status']['message']),
                                "top",
                                "right");
                        }
                    }
                },
                err => {
                    console.log('err', err);

                    this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
                        "top",
                        "right");
                    this.loading = false;
                }
            );
    }

    // getItems() {
    //     console.log('this.user: ', this.user);
    //     let request = {
    //         user: this.user.id,
    //         data: {id:this.user.id},
    //         index: this.currentPage,
    //         size: this.itemsPerPage
    //     }

    //     console.log('request: ', JSON.stringify(request));

    //     this.busyGet = this.restClient.post(this.endPoint + '/getByCriteria', request, 'civt2').subscribe({
    //         next: (res: any) => {
    //             console.log('res here', res);

    //             if (res && res.items && !res.hasError) {
    //                 this.items = res.items;
    //                 this.totalItems = res.count;

    //            this.items.map((it: any) => {
    //                     it.image_url = it.image_url??''
    //                     it.last_connection = it.last_connection != null ? moment(it.last_connection).format('DD/MM/YYYY HH:mm') : ''
    //                 })
    //                 console.log('this.items: ',this.items);
                    
    //             }
    //             else {
    //                 this.items = [];
    //                 this.totalItems = 0;
    //                 if (res.hasError) {
    //                     this.utilities.showNotification("snackbar-danger",
    //                         this.utilities.formatMsgServeur(res.status.message),
    //                         "top",
    //                         "right");
    //                     return
    //                 }
    //             }

    //         },
    //         error: (err) => {

    //             this.utilities.showNotification("snackbar-danger",
    //                 this.utilities.formatMsgServeur(err),
    //                 "top",
    //                 "right");
    //         },
    //         complete: () => console.info('complete')
    //     })
    // }

    getItemsRoles() {
        let request = {
            user: this.user.id,
            data: {},
            index: 1,
            size: 100000
        }

        console.log('res: ', request);

        this.busyGet = this.restClient.post('iwRoles/getByCriteria', request).subscribe({
            next: (res: any) => {
                console.log('res here', res);

                if (res && res.items && !res.hasError) {
                    this.itemsRoles = res.items;
                }
                else {
                    this.itemsRoles = [];
                    if (res.hasError) {
                        this.utilities.showNotification("snackbar-danger",
                            this.utilities.formatMsgServeur(res.status.message),
                            "top",
                            "right");
                        return
                    }
                }

            },
            error: (err) => {

                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur(err),
                    "top",
                    "right");
            },
            complete: () => console.info('complete')
        })
    }

    getItemsSecteur() {
        let request = {
            user: this.user.id,
            data: {},
            index: 1,
            size: 100000
        }

        console.log('res: ', request);

        this.busyGet = this.restClient.post('iwSecteur/getByCriteria', request).subscribe({
            next: (res: any) => {
                console.log('res here', res);

                if (res && res.items && !res.hasError) {
                    this.itemsSecteur = res.items;
                }
                else {
                    this.itemsSecteur = [];
                    if (res.hasError) {
                        this.utilities.showNotification("snackbar-danger",
                            this.utilities.formatMsgServeur(res.status.message),
                            "top",
                            "right");
                        return
                    }
                }

            },
            error: (err) => {

                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur(err),
                    "top",
                    "right");
            },
            complete: () => console.info('complete')
        })
    }


    uploadFile(event: any) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0]

            const Tabextension = file.name.split(".");
            const extension = Tabextension[Tabextension.length - 1];
            console.log('ext: ', extension);

            if (extension != 'png' && extension != 'jpeg' && extension != 'jpg' && extension != 'gif') {
                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur('Veuillez choisir une image valide'),
                    "top",
                    "right");
                return
            }
            this.selectedFile = file
            reader.onload = (readerEvent) => {
                const data = (readerEvent.target as any).result;
                this.imageDisplay = data;
            };
        }

        reader.readAsDataURL(event.target.files[0]);
    }


    setFormDataProperties(data) {
        var formData = new FormData();
        formData.append('user', this.user.id);
        formData.append('file', this.selectedFile);
        formData.append('secteur', this.itemToSave.secteur);
        formData.append('role', this.itemToSave.role);
        formData.append('sexe', this.itemToSave.sexe);
        formData.append('pays', this.itemToSave.pays);
        formData.append('date_naissance', moment(this.itemToSave.date_naissance).format('YYYY-MM-DD'));
        formData.append('contact', this.itemToSave.contact);
        // formData.append('date_debut_validite', this.itemToSave.date_debut_validite);
        formData.append('email', this.itemToSave.email);
        formData.append('login', this.itemToSave.login);
        formData.append('prenom', this.itemToSave.prenom);
        formData.append('nom', this.itemToSave.nom);
        formData.append('id', this.itemToSave.id)
        return formData
    }

    checkPermission(code) {

        return this.permission.checkMenu(code)
    }
    ngOnInit() {
        // this.getItems();
        // this.imageDisplay ='assets/images/custom-images/anonymous-user.jpg'
        this.itemToSave = this.user
        this.imageDisplay = this.user.image_url??this.user.image_url
        if (this.user.date_naissance) {
            this.user.date_naissance = moment(this.user.date_naissance, 'YYYY-MM-DD').toDate()
        }
        this.getItemsSecteur();
        this.getItemsRoles();


    }

}
