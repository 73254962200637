<section class="content" [ngBusy]="{
    busy: busyGet,
    message: 'Veuillez patienter ...',
    backdrop: true
  }" >
    <div class="content-block">

        <div class="row" style="margin-top: 5px;">
            <div class="col-md-2">
                <strong>Debut hospit</strong>
                <input type="text" (bsValueChange)="selectStartDate($event)" placeholder="Plage de date"
                    autocomplete="off" class="form-control" bsDatepicker [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-dark-blue'}" name="bsValue" [(ngModel)]="minDate" />
            </div>
            <div class="col-md-2">
                <strong>Fin hospit</strong>
                <input type="text" (bsValueChange)="selectEndDate($event)" placeholder="Plage de date"
                    autocomplete="off" class="form-control" bsDatepicker [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-dark-blue'}" name="bsValue" [(ngModel)]="maxDate" />
            </div>
            <div class="col-md-3" *ngIf="checkPermission('TOUT_SECTEUR')">
                <strong>Secteur</strong>
                <select class="form-control" name="secteurId" [(ngModel)]="itemToSearch.secteurId" (change)="onSelectSecteur();getItems()">
                    <option value="">Tous les secteurs</option>
                    <option *ngFor="let item of itemsSecteur " [(value)]="item.id">{{item.libelle}}</option>
                </select>
            </div>
            <div class="col-md-3" *ngIf="checkPermission('TOUT_SECTEUR')">
                <strong>Centre</strong>
                <select (change)="getItems()" class="form-control" name="centreId" [(ngModel)]="itemToSearch.centreId">
                    <option value="">Tous les centres</option>
                    <option *ngFor="let item of itemsCentre " [(value)]="item.id">{{item.libelle}}</option>
                </select>
            </div>
            <div class="col-md-1">
                <br>
                <button class="btn btn-primary" (click)="getItems()" ><i class="fa fa-search"></i></button>
            </div>
           
        </div>

        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header mb-3">
                        <h2><strong>Localisation</strong></h2>
                    </div>
                    <div class="body">
                        <agm-map [zoom]="zoom" class="map" [latitude]="latitude" [longitude]="longitude">
                            <!-- <agm-marker *ngFor="let item of items" [latitude]="item.latitude" [longitude]="item.longitude"></agm-marker> -->
                            <agm-marker  *ngFor="let item of list_position"  [latitude]="item.latitude" [longitude]="item.longitude"></agm-marker>

                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






