import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { DashboardGuard } from "./core/guard/dashboard.guard";
import { Role } from "./core/models/role";
import { DashboardComponent } from "./custom/dashboard/dashboard.component";
import { LocalisationComponent } from "./custom/localisation/localisation.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
const routes: Routes = [
    {
        path: "",
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
            {
                path: "controle-hospitalisation",
                canActivate: [AuthGuard],
                data: {
                    role: Role.Admin,
                },
                loadChildren: () =>
                    import("./custom/espace-controlleur/controle-hospitalisation/controle-hospitalisation.module").then((m) => m.ControleHospitalisationModule),
            },
            {
                path: "administration",
                canActivate: [AuthGuard],
                data: {
                    role: Role.Admin,
                },
                loadChildren: () =>
                    import("./custom/administration/administration.module").then((m) => m.AdministrationModule),
            },
            {
                path: "parametres",
                canActivate: [AuthGuard],
                data: {
                    role: Role.Admin,
                },
                loadChildren: () =>
                    import("./custom/parametres/parametres.module").then((m) => m.ParametresModule),
            },
            {
                path: "controle-hospitalisation",
                canActivate: [AuthGuard],
                data: {
                    role: Role.Admin,
                },
                loadChildren: () =>
                    import("./custom/espace-controlleur/espace-controlleur.module").then((m) => m.EspaceControlleurModule),
            },
            {
                path: "tableau-de-bord",
                canActivate: [AuthGuard,DashboardGuard],
                data: {
                    role: Role.Admin,
                },
                component: DashboardComponent,
            },
            {
                path: "localisation",
                // canActivate: [AuthGuard,DashboardGuard],
                // data: {
                //     role: Role.Admin,
                // },
                component: LocalisationComponent,
            },
        ],
    },
    {
        path: "authentication",
        component: AuthLayoutComponent,
        loadChildren: () =>
            import("./authentication/authentication.module").then(
                (m) => m.AuthenticationModule
            ),
    },
    { path: "**", component: Page404Component },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
