import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    constructor(private authService:AuthService) { }

    checkMenu(code: string): Boolean {
        let listFonctionnalites = this.authService.connectedUser()?.fonctionnalites
        return !!(listFonctionnalites.filter(funct => funct.code == code).length)
        // return true
    }

}
