<div class="modal-header">
    <h4 class="modal-title pull-left">
        Gestion profil
    </h4>

    <button mat-icon-button class="pull-right" (click)="bsModalRef.hide()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="modal-body" [ngBusy]="{
      busy: busySave,
      message: 'Veuillez patienter ...',
      backdrop: false
    }">
    <ng-container>
        <div class="row">
            <div class="col-12">
                <form class="m-1">
                    <div class="row" style="padding: 0">
                        <div class="col-md-4" style="padding-left: 5px; padding-right: 5px">
                            <div class="row">
        
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <label>Nom :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom-input" placeholder="Libellé" autocomplete="off"
                                        [(ngModel)]="itemToSave.nom" name="nom" autocomplete="off" type="text" />
                                </div>
        
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <label>Prenoms :
                                        <span class="required_champs">*</span>
                                    </label>
                                    <input class="form-control custom-input" placeholder="Prenoms" autocomplete="off"
                                        [(ngModel)]="itemToSave.prenom" name="prenom" autocomplete="off" type="text" />
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Role :
                                                <span class="required_champs">*</span>
                                            </label>
                                            <select disabled name="role" [(ngModel)]="itemToSave.role" class="form-control custom-input">
                                                <option value="">Choisir role</option>
                                                <option *ngFor="let item of itemsRoles" [(value)]="item.id">
                                                    {{ item.libelle }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Secteur :
                                                <span class="required_champs">*</span>
                                            </label>
                                            <select name="secteur" [(ngModel)]="itemToSave.secteur" disabled
                                                class="form-control custom-input">
                                                <option value="">Choisir secteur</option>
                                                <option *ngFor="let item of itemsSecteur" [(value)]="item.id">
                                                    {{ item.libelle }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mb-8">
                            <div class="row" style="padding: 0">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                            <label>Contact : <span class="required_champs">*</span> </label>
                                            <input class="form-control custom-input" placeholder="Contact" autocomplete="off"
                                                [(ngModel)]="itemToSave.contact" name="contact" mask="00-00-00-00-00" required
                                                type="text" />
                                        </div>
        
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                            <label>E-mail : <span class="required_champs">*</span> </label>
                                            <input class="form-control custom-input" placeholder="E-mail" autocomplete="off"
                                                [(ngModel)]="itemToSave.email" name="email" required type="text" />
                                        </div>
                                        <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                          <label>Login :
                            <span class="required_champs">*</span>
                          </label>
                          <input style="display: block" class="form-control custom-input" placeholder="Login" autocomplete="off"
                            [(ngModel)]="itemToSave.login" name="login" required type="text" />
                        </div> -->
        
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-1">
                                            <label>Date de naissance :
                                                <span class="required_champs">*</span>
                                            </label>
                                            <input type="text" placeholder="Date de naissance" autocomplete="off"
                                                class="form-control" bsDatepicker [bsConfig]="{
                                                    dateInputFormat: 'DD/MM/YYYY',
                                                    containerClass: 'theme-dark-blue'
                                                  }" [(ngModel)]="itemToSave.date_naissance" name="bsValue"
                                                [maxDate]="maxDate" />
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-1">
                                            <label>Login :
                                                <span class="required_champs">*</span>
                                            </label>
                                            <input class="form-control custom-input" placeholder="Login" autocomplete="off"
                                                [(ngModel)]="itemToSave.login" name="login" required type="text" />
                                        </div>
        
        
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1 mt-2">
                                            <div class="col-md-12">
                                                    <img *ngIf="itemToSave.id" [src]="('//'+ imageDisplay | safe)" style="
                                                    width: 100%;
                                                    height: 200px;
        
                                                    display: block;
                                                  " alt="User">
        
                                                   <img *ngIf="!itemToSave.id" [src]="imageDisplay" style="
                                                   width: 100%;
                                                   height: 200px;
        
                                                   display: block;
                                                 " alt="User">
                                                <input hidden id="img" type="file" class="custom-input form-control"
                                                    (change)="uploadFile($event)" accept=".png, .jpg, .jpeg, .bmp"
                                                    name="photo" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <label for="img" style="
                                                    width: 100%;
                                                    background-color: #0465aa;
                                                    padding: 5px;
                                                  ">
                                                <span style="color: white; padding: 5px">Choisir une image</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</div>

<div class="modal-footer modal-footer-custom">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <button type="button" (click)="confirmSaveItem(itemToSave)" class="btn btn-md custom_btn_primary pull-right"
            [class.auth-spinner]="loading" [disabled]="loading">
            {{ itemToSave?.id ? "Enregistrer" : "Enregistrer" }}
        </button>

        <button type="button" class="btn btn-md btn-cancel pull-right" (click)="bsModalRef.hide()">
            Annuler
        </button>
    </div>
</div>