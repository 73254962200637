import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { RestClientService } from "./rest-client.service";
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  // public connectedUser = 1

  constructor(private http: HttpClient,private router:Router,private restClient:RestClientService) {
    // this.currentUser = this.connectedUser()
  }

  public get currentUserValue(): User {
    return this.connectedUser()
  }

  // public connectedUser = () => localStorage.getItem("currentUser")?JSON.parse(localStorage.getItem("currentUser")).items[0]:[]
  public connectedUser = () => {
    let currentUser = localStorage.getItem("xxsds")?this.decryptMessage(localStorage.getItem("xxsds")):'{}'
    // return Object.keys(currentUser).length === 0?{}:JSON.parse(currentUser).items[0]
    // console.log('currentUser: ',currentUser);
    
    return (Object.keys(currentUser).length === 0 || !JSON.parse(currentUser) || !JSON.parse(currentUser).items || !(JSON.parse(currentUser).items).length)?{}:JSON.parse(currentUser).items[0]

  }


  login(username: string, password: string) {

    let request: any = {}

    request.data = {
      login: username,
      mot_de_passe: password
    }
    if (this.validateEmail(username)) {
      request.data = {
        email: username,
        mot_de_passe: password
      }
    }

    return this.http
      .post<any>(`${environment.apiUrl}/iwUsers/login`, request)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("xxsds", this.encryptMessage(JSON.stringify(user)));
          // localStorage.setItem("xxsds", this.encrypt(JSON.stringify(user)));

          // this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  isMenuFound(code: string): Boolean {
    // let listFonctionnalites = JSON.parse(localStorage.getItem("currentUser")).items[0]?.fonctionnalites
    let listFonctionnalites = this.connectedUser()?.fonctionnalites
    let check = !!(listFonctionnalites.filter(funct => funct.code == code).length)
    return check
    // return true
}


  logout() {
    // remove user from local storage to log user out
    let request: any = {}
    // console.log("localStorage.getItem('currentUser'): ",localStorage.getItem('currentUser'));
    if(localStorage.getItem('currentUser') == null || !JSON.parse(localStorage.getItem("currentUser")) || !JSON.parse(localStorage.getItem("currentUser")).items){
        localStorage.removeItem("currentUser");
        this.router.navigate(["/authentication/signin"]);
        return
    }

    request.data = {
      login: JSON.parse(localStorage.getItem("currentUser")).items[0].login,
      email: JSON.parse(localStorage.getItem("currentUser")).items[0].email
    }
    this.restClient.post('iwUsers/logout', request).subscribe({
        next: (res: any) => {
            this.router.navigate(["/authentication/signin"]);
        },
        error: (err) => {
          console.log('error deconnexion',err);
        },
        complete: () => console.info('complete')
      })
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  encryptMessage(message){
    // Chiffrement
    let secretKey = 'xxsdd/4745dfd45fdifjdhfehhhshkjekjgjjdkenkskhekkfkao'
    const encryptedMessage = CryptoJS.AES.encrypt(message, secretKey).toString();
    // console.log('CryptoJS.AES.encrypt(message, secretKey).toString(): ',CryptoJS.AES.encrypt(message, secretKey).toString());
    
    // const encryptedMessage = message

    return encryptedMessage
  }

  decryptMessage(encryptedMessage){
    // Déchiffrement
    let secretKey = 'xxsdd/4745dfd45fdifjdhfehhhshkjekjgjjdkenkskhekkfkao'
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
    const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8)
    return decryptedMessage
  }
}
