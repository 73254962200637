import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import Swal from "sweetalert2";
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import { PermissionService } from 'src/app/core/service/permission.service';
@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss']
})
export class LocalisationComponent implements OnInit {
  latitude=5.328049
  longitude=-4.0184761
  zoom = 12
  list_position=[
    
  ]
    listSelectedCentre = []
    listItems: Array<any> = [];
    items: Array<any> = [];
    itemToSave: any = {};
    modalRef: BsModalRef;
    user: any = {};
    itemToSearch: any = {};
    currentPage: number = 1;
    itemsPerPage: number = 10;
    totalItems: number;
    busyGet: Subscription;
    busySave: Subscription;
    loading: boolean = false;
    endPoint: string = 'iwSecteur';
    maxSize = 10
    title = "Secteur"
    isEditMode: boolean = false
    itemsTypeRejet: any;
    maxValue: any;
    itemsListCentres: any = [];
    itemsListRelatedCentres: any = [];
    currentSecteurId: any;
    minDate = new Date();
    maxDate = new Date();
  itemsSecteur: any;
  itemsCentre: any[];
    constructor(private authService: AuthService, private restClient: RestClientService, private permission: PermissionService, private modalService: BsModalService, private utilities: UtilitiesService) {
        this.user = this.authService.connectedUser()
        let now = new Date();
        let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        this.minDate = firstDay
        this.maxDate = lastDay
    }

  ngOnInit(): void {
    // this.getItems()
    this.getItemsSecteurs()
  }

  ngAfterViewInit() {
   
  }

  getItems() {
    // if(!this.itemToSearch.centreId){
    //   this.utilities.showNotification("snackbar-danger",
    //             "Veuillez chosir centre",
    //             "top",
    //             "right")
    //             return
    // }
    this.itemToSearch.lbc_centre = this.itemToSearch.centreId
    let request = {
        user: this.user.id,
        data: this.itemToSearch,
        index: this.currentPage,
        size: this.itemsPerPage
    }

    console.log('res: ', request);

    this.busyGet = this.restClient.post('centre/getGpsPosition', request, 'civt2').subscribe({
        next: (res: any) => {
            console.log('res here', res);

            if (res && res.items && !res.hasError) {
                this.items = res.items;
                this.list_position= this.items
                this.latitude = res.items[0].lattitude
                this.longitude = res.items[0].longitude
                this.list_position.map((lp:any)=>{
                  lp.latitude = parseFloat(lp.lattitude)
                  lp.longitude = parseFloat(lp.longitude)
                })
                console.log('list_position: ',this.list_position)
                // this.totalItems = res.count;
            }
            else {
                this.items = [];
                this.totalItems = 0;
                if (res.hasError) {
                    this.utilities.showNotification("snackbar-danger",
                        this.utilities.formatMsgServeur(res.status.message),
                        "top",
                        "right");
                    return
                }
            }

        },
        error: (err) => {

            this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(err),
                "top",
                "right");
        },
        complete: () => console.info('complete')
    })
}

selectStartDate(event) {
  console.log('event', event);
  this.itemToSearch.start_date = moment(event).format("YYYY-MM-DD")
}

selectEndDate(event) {
  console.log('event', event);
  this.itemToSearch.end_date = moment(event).format("YYYY-MM-DD")
}

checkPermission(code) {

  return this.permission.checkMenu(code)
}

getItemsSecteurs() {
  let request = {
      user: this.user.id,
      index: 1,
      data: {},
      size: 100000
  }

  console.log('res: ', request);

  this.busyGet = this.restClient.post('iwSecteur/getByCriteria', request).subscribe({
      next: (res: any) => {
          console.log('res here', res);

          if (res && res.items && !res.hasError) {
              this.itemsSecteur = res.items;
              this.itemsSecteur = this.itemsSecteur.filter(is=>!is.isOther)

          }
          else {
              this.itemsSecteur = [];
              if (res.hasError) {
                  this.utilities.showNotification("snackbar-danger",
                      this.utilities.formatMsgServeur(res.status.message),
                      "top",
                      "right");
                  return
              }
          }

      },
      error: (err) => {

          this.utilities.showNotification("snackbar-danger",
              this.utilities.formatMsgServeur(err),
              "top",
              "right");
      },
      complete: () => console.info('complete')
  })
}

getCentresBySecteur(){
  this.itemsCentre = [];

  if(! this.itemToSearch.secteurId){
      return
  }
  let request = {
      user: this.user.id,
      data: {
          secteur: this.itemToSearch.secteurId,
      },
      index: 1,
      size: 10000000
    }

    console.log('res: ', request);

    this.busyGet = this.restClient.post('iwCentreSecteur/getByNoRename', request).subscribe({
      next: (res: any) => {
        console.log('res here', res);

        if (res && res.items && !res.hasError) {
          let reduce=[]
          res.items.map(it=>{
              reduce.push({id:it.centre,libelle:it.libelle})
          });

          this.itemsCentre = reduce
          console.log('itemsCentre: ',JSON.stringify(this.itemsCentre));
        
        }
        else {
          this.itemsCentre = [];
        
        }

      },
      error: (err) => {

        this.utilities.showNotification("snackbar-danger",
          this.utilities.formatMsgServeur(err),
          "top",
          "right");
      },
      complete: () => console.info('complete')
    })
}

onSelectSecteur(){
  this.getCentresBySecteur()
}
}
