<section class="content" [ngBusy]="{
    busy: busyGet,
    message: 'Veuillez patienter ...',
    backdrop: true
  }">
    <div class="content-block">



        <!-- <img [src]="userImg | safe" class="user_img" width="32" height="32" alt="User"> -->
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row" style="margin-top: 5px;">
                    <div class="col-md-3">
                        <strong>Debut hospit</strong>
                        <input type="text" (bsValueChange)="selectStartDate($event)" placeholder="Plage de date"
                            autocomplete="off" class="form-control" bsDatepicker [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY',
                        containerClass: 'theme-dark-blue'}" name="bsValue" [(ngModel)]="minDate" />
                    </div>
                    <div class="col-md-3">
                        <strong>Fin hospit</strong>
                        <input type="text" (bsValueChange)="selectEndDate($event)" placeholder="Plage de date"
                            autocomplete="off" class="form-control" bsDatepicker [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY',
                        containerClass: 'theme-dark-blue'}" name="bsValue" [(ngModel)]="maxDate" />
                    </div>
                    <div class="col-md-3" *ngIf="checkPermission('TOUT_SECTEUR')">
                        <strong>Secteur</strong>
                        <select class="form-control" name="secteurId" [(ngModel)]="itemToSearch.secteurId" (change)="refreshAll()">
                            <option value=""></option>
                            <option *ngFor="let item of itemsSecteur " [(value)]="item.id">{{item.libelle}}</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-3">
                        <app-search-centre [isClearUp]="'false'" (itemCentreSelected)="selectCentre($event)">
                        </app-search-centre>

                    </div> -->
                    <div class="col-md-1">
                        <br>
                        <button class="btn btn-primary" (click)="refreshAll()"><i class="fa fa-search"></i></button>
                    </div>
                    <div class="col-md-2" *ngIf="checkPermission('TOUT_SECTEUR')" style="text-align: right;">
                        <br>
                        <button class="btn btn-primary" (click)="generateReport()">Télécharger état <i class="fa fa-download"></i></button>
                    </div>
                </div>
                <br>
                <div class="row" style="margin-top: 2px;">
                    <div class="col-md-6 col-12">
                        <div class="card">
                            <div class="body">
                                <strong>Hospitalisation</strong>
                                <div [chart]="highchart"></div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 col-12">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="card card-statistic-2">
                                    <div class="clearfix">
                                        <div class="card-icon shadow-primary bg-purple">
                                            <i class="material-icons align-middle">workspaces</i>
                                        </div>
                                        <div class="card-right">
                                            <div>
                                                <h5 class="float-end">Secteurs</h5>
                                            </div>
                                            <div class="m-r-10 m-l-10">
                                                <h3 class="font-weight-bold float-end mb-0"> {{totalSecteurs
                                                    |moneyFormat}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-chart">
                                        <!-- <canvas baseChart [chartType]="'line'" [datasets]="cardChart1Data"
                                            [labels]="cardChart1Label" [options]="cardChart1" [legend]="true">
                                        </canvas> -->
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="card card-statistic-2">
                                    <div class="clearfix">
                                        <div class="card-icon shadow-primary bg-purple">
                                            <i class="material-icons align-middle">library_books</i>
                                        </div>
                                        <div class="card-right">
                                            <div>
                                                <h5 class="float-end">Controleurs</h5>
                                            </div>
                                            <div class="m-r-10 m-l-10">
                                                <h3 class="font-weight-bold float-end mb-0"> {{totalControleurs
                                                    |moneyFormat }}</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="card card-statistic-2">
                                    <div class="clearfix">
                                        <div class="card-icon shadow-primary bg-purple">
                                            <i class="material-icons align-middle">vaccines</i>
                                        </div>
                                        <div class="card-right">
                                            <div>
                                                <h5 class="float-end">Nombre hospit</h5>
                                            </div>
                                            <div class="m-r-10 m-l-10">
                                                <h3 class="font-weight-bold float-end mb-0">{{(totalHospit
                                                    |moneyFormat)||0}}</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="card card-statistic-2">
                                    <div class="clearfix">
                                        <div class="card-icon shadow-primary bg-purple">
                                            <i class="material-icons align-middle">local_hospital</i>
                                        </div>
                                        <div class="card-right">
                                            <div>
                                                <h5 class="float-end">Centres visités</h5>
                                            </div>
                                            <div class="m-r-10 m-l-10">
                                                <h3 class="font-weight-bold float-end mb-0"> x / y</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="card card-statistic-2">
                                    <div class="clearfix">
                                        <div class="card-icon shadow-primary bg-purple">
                                            <i class="material-icons align-middle">local_hospital</i>
                                        </div>
                                        <div class="card-right">
                                            <div>
                                                <h5 class="float-end">Satisfaction</h5>
                                            </div>
                                            <div class="m-r-10 m-l-10">
                                                <h3 class="font-weight-bold float-end mb-0"> {{average_evaluation}} / 60</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="card card-statistic-2">
                                    <div class="clearfix">
                                        <div class="card-icon shadow-primary bg-purple">
                                            <i class="material-icons align-middle">local_hospital</i>
                                        </div>
                                        <div class="card-right">
                                            <div>
                                                <h5 class="float-end">Durée (Jours)</h5>
                                            </div>
                                            <div class="m-r-10 m-l-10">
                                                <h3 class="font-weight-bold float-end mb-0">{{average_duration}}</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>