import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import Swal from "sweetalert2";
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import { Chart } from 'angular-highcharts';
import { PermissionService } from 'src/app/core/service/permission.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    modalRef: BsModalRef;
    user: any = {};
    itemToSearch: any = {};
    busyGet: Subscription
    data = [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
    highchart: any
    items: any;
    bsRangeValue: Date[];
    minDate = new Date();
    maxDate = new Date();
    currentDate = new Date();
    totalSecteurs = 0
    totalControleurs = 0
    totalHospit: any;
    itemsSecteur: any;
    userImg:any
    colors = ['#236CB7', '#644033', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417', '#644033', '#F7B400', '#A08377', '#C3BEB7', '#9F7417'];
    average_evaluation=0;
    average_duration=0;
    latitude: number | undefined;
    longitude: number | undefined;
    constructor(private permission:PermissionService ,private httpClient:HttpClient,private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
        this.user = this.authService.connectedUser()
        // this.maxDate.setDate(this.currentDate.getDate()+30);
        // this.minDate.setDate(this.currentDate.getDate()-30);
        let now = new Date();
        let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        this.minDate = firstDay
        this.maxDate = lastDay





        
    }




    getVistesByMonth() {
        let request = {
            user: this.user.id,
            data: this.itemToSearch
        }
        console.log('res: ', request);

        this.busyGet = this.restClient.post('kpi/getVitesByMonth', request).subscribe({
            next: (res: any) => {
                console.log('res here', res);

                if (res && res.items && !res.hasError) {
                    this.items = res.items;
                }
                else {
                    this.items = [];
                    if (res.hasError) {
                        this.utilities.showNotification("snackbar-danger",
                            this.utilities.formatMsgServeur(res.status.message),
                            "top",
                            "right");
                        return
                    }
                }
                this.drawBarChart(this.items)


            },
            error: (err) => {

                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur(err),
                    "top",
                    "right");
            },
            complete: () => console.info('complete')
        })
    }

    getTotalInfos() {
        let request = {
            user: this.user.id,
            data: this.itemToSearch
        }
        console.log('res: ', request);

        this.busyGet = this.restClient.post('kpi/getTotalInfos', request).subscribe({
            next: (res: any) => {
                console.log('res here', res);

                if (res && res.data && !res.hasError) {
                    this.totalSecteurs = res.data.secteurs;
                    this.totalControleurs = res.data.controlleurs
                    this.totalHospit = res.data.totalHospit
                    this.average_evaluation = res.data.average
                    this.average_duration = res.data.average_duration
                }
                else {
                    this.totalSecteurs = 0
                    this.items = [];
                    if (res.hasError) {
                        this.utilities.showNotification("snackbar-danger",
                            this.utilities.formatMsgServeur(res.status.message),
                            "top",
                            "right");
                        return
                    }
                }

            },
            error: (err) => {

                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur(err),
                    "top",
                    "right");
            },
            complete: () => console.info('complete')
        })
    }


    drawBarChart(datas) {
        let itemToDraw = []
        let itemToDrawFull = []

        let categories = []


        datas.map(d => {
            itemToDraw.push(d.value)
            itemToDrawFull.push(d.valueFull)
            categories.push(moment(d.key).format('MM/YY'))
        })
        console.log('to draw: ', itemToDraw);

        this.highchart = new Chart({
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            colors: this.colors,
            series: [

                {
                    type: 'column',
                    name: 'Visites',
                    data: itemToDraw
                },
                {
                    type: 'column',
                    name: 'Hospit',
                    data: itemToDrawFull
                },


            ]
        });
    }
    refreshAll() {
        if(moment(this.minDate).isAfter(this.maxDate)){
            this.utilities.showNotification("snackbar-danger",
            'La date de fin doit etre supérieure à la date de debut',
            "top",
            "right");
    
            return
        }
        this.getVistesByMonth()
        this.getTotalInfos()
    }
  
    selectCentre(event) {
        console.log('centre: ', event);

    }

    getItemsSecteurs() {
        let request = {
            user: this.user.id,
            index: 1,
            data: {},
            size: 100000
        }

        console.log('res: ', request);

        this.busyGet = this.restClient.post('iwSecteur/getByCriteria', request).subscribe({
            next: (res: any) => {
                console.log('res here', res);

                if (res && res.items && !res.hasError) {
                    this.itemsSecteur = res.items;
                    this.itemsSecteur = this.itemsSecteur.filter(is=>!is.isOther)

                }
                else {
                    this.itemsSecteur = [];
                    if (res.hasError) {
                        this.utilities.showNotification("snackbar-danger",
                            this.utilities.formatMsgServeur(res.status.message),
                            "top",
                            "right");
                        return
                    }
                }

            },
            error: (err) => {

                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur(err),
                    "top",
                    "right");
            },
            complete: () => console.info('complete')
        })
    }

    checkPermission(code) {

        return this.permission.checkMenu(code)
    }

    selectStartDate(event) {
        console.log('event', event);
        this.itemToSearch.start_date = moment(event).format("YYYY-MM-DD")
    }

    selectEndDate(event) {
        console.log('event', event);
        this.itemToSearch.end_date = moment(event).format("YYYY-MM-DD")
    }

    generateReport(){
        let request = {
            user: this.user.id,
            index: 1,
            data: {
                start_date: this.minDate,
                end_date: this.maxDate,
                secteurId: this.itemToSearch.secteurId || ''
            },
            size: 100000
        }

        console.log('res: ', request);

        this.busyGet = this.restClient.post('kpi/exportDataVisites', request).subscribe({
            next: (res: any) => {
                console.log('res here', res);

                if (res && res.items && !res.hasError) {
                    let url = res.items[0].excel_file
                    window.open(url);
                }
                else {
                    this.itemsSecteur = [];
                    if (res.hasError) {
                        this.utilities.showNotification("snackbar-danger",
                            this.utilities.formatMsgServeur(res.status.message),
                            "top",
                            "right");
                        return
                    }
                }

            },
            error: (err) => {

                this.utilities.showNotification("snackbar-danger",
                    this.utilities.formatMsgServeur(err),
                    "top",
                    "right");
            },
            complete: () => console.info('complete')
        })
        
        
    }
    ngOnInit() {
        this.selectStartDate(this.minDate)
        this.selectEndDate(this.maxDate)

        if(!this.checkPermission('TOUT_SECTEUR')){
            this.itemToSearch.secteurId = this.user.secteur
        }
        this.userImg=JSON.parse(localStorage.getItem('currentUser')) || 'assets/images/custom-images/anonymous-user.jpg';
        this.getItemsSecteurs()
        this.refreshAll()

    }


    

}
